import React, { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import PopoverInteractiveStatic from "./PopoverInteractiveStatic"
import { useStaticQuery, graphql } from "gatsby"

import {
  Button,
  Box,
  Flex,
  Text,
  Center,
  Circle,
  List,
  ListItem,
  VStack,
  Heading,
  useToast,
  Skeleton,
  Image,
  Spacer,
} from "@chakra-ui/react"
import MarkerModal from "./MarkerModal"
import useAPI from "../../hooks/useAPI"

const emptyTempObject = {
  x: 0, // number
  y: 0, // number
  key: "unsetKey", // unique string
  type: "static", // "static" | "interactive"
  visibility: "top", // "top" | "bottom"
  icon: "", // string
  canExpand: true, // boolean
  title: "", // string
  image: "", // string
  link: {
    title: "", // string
    url: "", // string
  },
  expandedContent: {
    title: "", // string
    content: "", // string
    link: {
      title: "", // string
      url: "", // string
    },
  },
}

const PSDSidebar = props => {
  const sendActionToStore = useDispatch()
  const psd_store = useSelector(state => state.projectSceneDesigner)
  const toast = useToast()
  const { postRequest, getRequest } = useAPI()

  const [staticIconData, setStaticIconData] = useState([])
  const [staticImageData, setStaticImageData] = useState([])
  const [staticProjektzielData, setStaticProjektzielData] = useState([])

  // * States
  const [staticDataLoaded, setStaticDataLoaded] = useState(false)
  const [tempMarkerObject, setTempMarkerObject] = useState(emptyTempObject)
  const [wpMarkersLoaded, setWpMarkersLoaded] = useState(false)
  const [unsavedMarkerData, setUnsavedMarkerData] = useState([])

  // * Get static data from Wordpress

  const staticWPdata = useStaticQuery(
    graphql`
      query {
        allWpMapIcon {
          edges {
            node {
              title
              acf_map_icon {
                imageIcon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
        allWpMediaItem {
          edges {
            node {
              title
              localFile {
                publicURL
              }
            }
          }
        }
        allWpProjektziel {
          edges {
            node {
              id
              title
              uri
              slug
              acf_projektziel_content {
                groupLeft {
                  textProjektname
                  relationMobilitaetsschwerpunkt {
                    ... on WpSchwerpunkt {
                      slug
                    }
                  }
                  imageFeaturedImage {
                    title
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    if (!staticDataLoaded) {
      setStaticIconData(() =>
        staticWPdata.allWpMapIcon.edges.map(icon => {
          return {
            name: icon.node.title,
            svg: icon.node.acf_map_icon.imageIcon.localFile.publicURL,
          }
        })
      )

      // staticImageData = staticWPdata.allWpMediaItem.edges.map(image => {
      //   if (image.node.localFile !== null) {
      //     return {
      //       name: image.node.title,
      //       url: image.node.localFile.publicURL,
      //     }
      //   } else {
      //     return {}
      //   }
      // })
      let page = 1

      const getImage = async () => {
        let per_page = 100
        return await getRequest(
          `/media?per_page=${per_page}&page=${page}&context=embed&media_type=image`
        ).then(res => {
          if (res.length === per_page) {
            setStaticImageData(data => {
              let map = res.map(image => {
                if (image.source_url !== null) {
                  return {
                    name: image.title.rendered,
                    url: image.media_details.sizes
                      ? image.media_details.sizes.large
                        ? image.media_details.sizes.large.source_url
                        : image.media_details.sizes.full
                        ? image.media_details.sizes.full.source_url
                        : image.media_details.sizes.medium.source_url
                      : image.source_url,
                  }
                } else {
                  return {}
                }
              })
              return [...data, ...map]
            })
            page++
            getImage()
          } else {
            setStaticImageData(data => {
              let map = res.map(image => {
                if (image.source_url !== null) {
                  return {
                    name: image.title.rendered,
                    url: image.media_details.sizes
                      ? image.media_details.sizes.large
                        ? image.media_details.sizes.large.source_url
                        : image.media_details.sizes.full
                        ? image.media_details.sizes.full.source_url
                        : image.media_details.sizes.medium.source_url
                      : image.source_url,
                  }
                } else {
                  return {}
                }
              })
              return [...data, ...map]
            })
            if (res.length >= per_page - 1) {
              page++
              getImage()
            }
          }
        })
      }
      getImage()

      function fixUrl(slug, locale, schwerpunkt) {
        console.log("fixUrl!")
        if (locale === "en") {
          return `/en/mobility-focus/${schwerpunkt}/projectgoals/${slug}`
        }
        return `/mobilitaetsschwerpunkte/${schwerpunkt}/projektziele/${slug}`
      }

      // console.log(staticImageData)
      setStaticProjektzielData(() =>
        staticWPdata.allWpProjektziel.edges.map(projektziel => {
          // console.log("Projektziel:", projektziel)
          return {
            title: projektziel.node.title,
            projectTitle:
              projektziel.node.acf_projektziel_content.groupLeft
                .textProjektname,
            url: fixUrl(
              projektziel.node.slug,
              "de",
              projektziel.node.acf_projektziel_content.groupLeft
                .relationMobilitaetsschwerpunkt.slug
            ),
            image:
              projektziel.node.acf_projektziel_content.groupLeft
                .imageFeaturedImage !== null
                ? projektziel.node.acf_projektziel_content.groupLeft
                    .imageFeaturedImage?.title
                : "",
          }
        })
      )

      // console.log(
      //   "Static Data",
      //   staticIconData,
      //   staticImageData,
      //   staticProjektzielData
      // )
      setStaticDataLoaded(true)
    }
  }, [])

  // * Toast Wrapper
  function pushToast(t, d, s, du, iC) {
    toast({
      title: t,
      description: d,
      status: s,
      duration: du,
      isClosable: iC,
    })
  }

  // * Adding & Removing Marker in store array
  function newMarker(m) {
    // console.log("new Marker!!!", m)
    const mWithAdditionalData = {
      ...m,
      x: psd_store.lastPosition.x,
      y: psd_store.lastPosition.y,
      // * Add unique key
      key: `markerKey_${m.title}_${(unsavedMarkerData.length + 1).toString()}`,
    }
    let umd = unsavedMarkerData
    umd.push(mWithAdditionalData)
    setUnsavedMarkerData(umd)
  }

  function removeMarker(m) {
    let umd = unsavedMarkerData
    umd.splice(umd.indexOf(m), 1)
    setUnsavedMarkerData(umd)
    pushToast(
      "Szenenojekt gelöscht",
      "Das Szenenobjekt wurde erfolgreich gelöscht.",
      "success",
      3000,
      true
    )
    props.refreshCallback()
  }

  useEffect(() => {
    // * Initial Markers from Wordpress
    if (props.wpMarkers !== undefined && props.wpMarkers && !wpMarkersLoaded) {
      // console.log("Setting unsavedMarker data to WP Data")
      let umd = unsavedMarkerData
      let combined = umd.concat(props.wpMarkers)
      setUnsavedMarkerData(combined)
      setWpMarkersLoaded(true)
      // console.log("Props: ", props.wpMarkers)
    } else {
      // console.log("wpMarkers undefined")
    }
  }, [props.wpMarkers, unsavedMarkerData, wpMarkersLoaded])

  function resetTemp() {
    setTempMarkerObject(emptyTempObject)
    // empty input fields
  }

  //  * Add marker to temporary list
  function addMarker(obj) {
    newMarker(obj)

    sendActionToStore({
      type: "change/map/marker",
      payload: unsavedMarkerData,
    })

    pushToast(
      "Szenenobjekt hinzugefügt",
      "Das Szenenobjekt wurde erfolgreich hinzugefügt.",
      "success",
      3000,
      true
    )

    resetTemp()

    props.refreshCallback()

    // * Preview Marker to Store
    sendActionToStore({
      type: "projectSceneDesigner/setPreviewMarker",
      payload: [
        {
          ...tempMarkerObject,
          x: -100,
          y: -100,
        },
      ],
    })

    // console.log("added")
  }

  function editMarker(obj) {
    let umd = unsavedMarkerData
    let index = umd.findIndex(i => i.x === obj.x)
    // console.log("Edit obj", obj)
    umd.splice(index, 1, obj)
    setUnsavedMarkerData(umd)
    props.refreshCallback()
  }

  function moveObjectToLastPosition(obj) {
    // * Get Object
    let umd = unsavedMarkerData
    let index = umd.findIndex(i => i.x === obj.x && i.title === obj.title)

    // * Set new position
    let tempObject = umd[index]
    tempObject = {
      ...tempObject,
      x: psd_store.lastPosition.x,
      y: psd_store.lastPosition.y,
    }

    // * Save object
    umd.splice(index, 1, tempObject)
    setUnsavedMarkerData(umd)

    // * Refresh
    props.refreshCallback()

    pushToast(
      "Objekt wurde verschoben!",
      "Das Objekt wurde erfolgreich verschoben.",
      "success",
      3000,
      true
    )
  }

  const [isSaving, setIsSaving] = useState(false)

  const handlePostWpData = useCallback(
    data => {
      setIsSaving(true)
      postRequest(`/projektziele/${props.currentPostID}`, {
        fields: data,
      }).then(res => {
        setIsSaving(false)
        // console.log("data", JSON.stringify(data))
        // console.log("result", res)
        pushToast(
          "Szenen wurden gespeichert!",
          "Die Szenen wurden erfolgreich für das Projektziel gespeichert.",
          "success",
          3000,
          true
        )
      })
    },
    [postRequest, props.currentPostID]
  )

  const StaticListItems = props => {
    let markerData = []
    if (
      props.unsavedMarkerData !== undefined ||
      !props.unsavedMarkerData ||
      props.unsavedMarkerData.length <= 0
    ) {
      markerData = props.unsavedMarkerData
    }

    function setFocusOnMarker(id, t) {
      if (
        document.getElementById("marker_" + t + "_" + id.substring(0, 3)) !=
        null
      ) {
        document
          .getElementById("marker_" + t + "_" + id.substring(0, 3))
          .focus()
      }
    }

    function removeFocus() {
      document.activeElement.blur()
    }

    const listItemTemplate = (item, index) => (
      <ListItem
        key={index.toString() + item.title}
        mt="2"
        borderRadius="8"
        border="2px"
        borderColor="gray.200"
        bg="white"
        p="2"
        minH="80px"
        onMouseEnter={() => setFocusOnMarker(item.title, item.type)}
        onMouseLeave={() => removeFocus()}
      >
        <Flex>
          <Circle w="16" h="16" p="0" mr="2" bg="gray.200">
            <Image src={item.icon} />
          </Circle>
          <Box width="50%" overflow="hidden">
            {item.type === "static" ? (
              <>
                <Heading fontSize="md">{item.title}</Heading>
                <Text>Icon</Text>
              </>
            ) : (
              <>
                <Heading fontSize="md">{item.title}</Heading>
                {item.canExpand && (
                  <Text isTruncated>{item.expandedContent.content}</Text>
                )}
                <Text>{item.link.title}</Text>
                <Text>
                  <b>Szene:</b>{" "}
                  {item.visibility === "top"
                    ? "Szene 1 & Szene 2"
                    : "Nur Szene 2"}
                </Text>
              </>
            )}
          </Box>
          <VStack width="35%">
            <MarkerModal
              type={item.type}
              markerObject={item}
              openButton="Bearbeiten"
              addMarkerFunction={editMarker}
              staticImageData={staticImageData}
              staticIconData={staticIconData}
              staticProjektzielData={staticProjektzielData}
            />
            <Flex width="100%">
              <Button
                onClick={() => moveObjectToLastPosition(item)}
                mr="1"
                size="xs"
              >
                Verschieben
              </Button>
              <Button
                onClick={() => removeMarker(item)}
                colorScheme="red"
                size="xs"
                ml="1"
              >
                Löschen
              </Button>
            </Flex>
          </VStack>
        </Flex>
      </ListItem>
    )

    if (props.type === "static") {
      return markerData.map((item, index) =>
        item.type === "static" ? listItemTemplate(item, index) : <></>
      )
    }
    if (props.type === "interactive") {
      return markerData.map((item, index) =>
        item.type === "interactive" ? listItemTemplate(item, index) : <></>
      )
    }

    return <>List type unset.</>
  }

  useEffect(() => {
    // * Load Markers from Project
    sendActionToStore({
      type: "change/map/marker",
      payload: unsavedMarkerData,
    })

    // * Preview Marker to Store
    sendActionToStore({
      type: "projectSceneDesigner/setPreviewMarker",
      payload: [
        {
          ...tempMarkerObject,
          x: psd_store.lastPosition.x,
          y: psd_store.lastPosition.y,
        },
      ],
    })
  }, [unsavedMarkerData, sendActionToStore, tempMarkerObject, psd_store])

  return (
    <Box zIndex="20" mt="2" borderTop="1px solid gray" w="100%" p="4">
      <Heading mt="4" fontSize="lg">
        <Flex alignItems="center">
          Statische Objekte <PopoverInteractiveStatic static />
          <span
            style={{
              fontWeight: "normal",
              fontSize: "0.8em",
              marginLeft: "8px",
            }}
          >
            (z.B. Inhaltliche Erweiterungen der 3D Karte)
          </span>
        </Flex>
      </Heading>
      <List
        className="overflowScroll"
        mt="2"
        border="1px solid #ddd"
        rounded="md"
        pr="2"
        pl="2"
        pb="2"
        maxHeight="260px"
        overflowY="scroll"
      >
        <StaticListItems type="static" unsavedMarkerData={unsavedMarkerData} />
      </List>
      <Skeleton isLoaded={staticDataLoaded}>
        <Center mt="2">
          <MarkerModal
            type="static"
            markerObject={emptyTempObject}
            addMarkerFunction={addMarker}
            staticImageData={staticImageData}
            staticIconData={staticIconData}
            staticProjektzielData={staticProjektzielData}
          />
        </Center>
      </Skeleton>
      <Heading mt="8" fontSize="lg">
        <Flex alignItems="center">
          Interaktive Objekte <PopoverInteractiveStatic interactive />
          <span
            style={{
              fontWeight: "normal",
              fontSize: "0.8em",
              marginLeft: "8px",
            }}
          >
            (z.B. Projekte, Repräsentanten)
          </span>
        </Flex>
      </Heading>
      <List
        className="overflowScroll"
        mt="2"
        border="1px solid #ddd"
        rounded="md"
        pr="2"
        pl="2"
        pb="2"
        maxHeight="260px"
        overflowY="scroll"
      >
        <StaticListItems
          type="interactive"
          unsavedMarkerData={unsavedMarkerData}
        />
      </List>
      <Center mt="2">
        <MarkerModal
          type="interactive"
          markerObject={emptyTempObject}
          addMarkerFunction={addMarker}
          staticImageData={staticImageData}
          staticIconData={staticIconData}
          staticProjektzielData={staticProjektzielData}
        />
      </Center>
      <Box h="64px" w="100%" />
      <Box
        position="fixed"
        bg="white"
        borderTop="1px"
        borderColor="gray.300"
        bottom="0px"
        right="0px"
        height="64px"
        width="34%"
        zIndex="30"
        display="flex"
      >
        <Box lineHeight="64px" ml="4">
          <a className="backLink" href={props.backLink}>
            {"Zurück zu Wordpress"}
          </a>
        </Box>
        <Spacer />
        <Button
          colorScheme="blue"
          mt="12px"
          mr="4"
          isLoading={isSaving}
          onClick={() =>
            handlePostWpData({
              location: props.location.databaseId,
              repeater_scene: unsavedMarkerData,
            })
          }
        >
          Speichern
        </Button>
      </Box>
    </Box>
  )
}

export default PSDSidebar
