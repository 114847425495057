import React from "react"
import {
  Circle,
  Text,
  Flex,
  Heading,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react"

const PopoverInteractiveStatic = props => {
  return (
    <Popover>
      <PopoverTrigger>
        <Flex cursor="pointer">
          {/* <Text h="32px" lineHeight="32px">
            Erklärung
          </Text> */}
          <Circle
            ml="2"
            fontWeight="bold"
            cursor="pointer"
            bg="gray.300"
            w="24px"
            h="24px"
          >
            ?
          </Circle>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Erklärung</PopoverHeader>
        <PopoverBody shadow="lg" fontWeight="normal">
          {props.static && (
            <>
              <Text>
                Statische Objekte beschreiben{" "}
                <b>mithilfe von Icons inhaltliche Erweiterungen der 3D Karte</b>
                . Als eine solche grafische Informationsschicht sind sie
                farblich an die 3D Karte angepasst und sind{" "}
                <b>weder klickbar</b> noch <b>verlinkbar</b>.
              </Text>
            </>
          )}

          {props.interactive && (
            <>
              <Text>
                Interaktive Objekte ermöglichen es{" "}
                <b>Inhalte des Guides zu verlinken</b>. Als Interaktionselemente
                bieten sie Möglichkeit <b>klickbare Icons</b>, kleines Overlays
                und ein erweitertes zweites Overlay zu platzieren.
              </Text>
            </>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverInteractiveStatic
